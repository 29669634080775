import "../styles/Navbar.scss";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import { Link } from 'react-router-dom';
import ModalCloseSesion from './ModalCloseSesion';
import BurgerButton from './BurgerButton';
import { useMediaQuery } from 'react-responsive';

function Navbar() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <React.Fragment>
      {isMobile ? <BurgerButton /> : <DesktopNavbar />}
    </React.Fragment>
  );
}

function DesktopNavbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // Estado para controlar el submenú
  const navigate = useNavigate(); // Hook para manejar la navegación

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    setIsLoggedIn(!!userData);
  }, []);

  const scrollToFooter = () => {
    const footer = document.getElementById('footer');
    footer.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLogout = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Alterna el submenú
  };

  // Función para manejar el clic en una opción del dropdown
  const handleDropdownClick = (category) => {
    navigate(`/blog/${category}`); // Cambia la URL dinámicamente
    setShowDropdown(false); // Cierra el dropdown después de seleccionar una opción
  };

  return (
    <nav className="navbar px-5">
      <div className="navbar-center">
        <Link to="/" className="nav-link">Inicio</Link>
        <Link to="/sobrenosotros" className="nav-link">Sobre nosotros</Link>
        <Link to="/" className="nav-link">Casos de éxito</Link>
        
        {/* Menú con Submenú */}
        <div className="nav-link dropdown">
          <span className="blog-dropdown" onClick={toggleDropdown}>Blog</span>
          <div className={`dropdown-menu ${showDropdown ? 'show' : ''}`}>
              <span className="dropdown-item" onClick={() => handleDropdownClick('oportunidades')}>Oportunidades Profesionales y Crecimiento Profesional</span>
              <span className="dropdown-item" onClick={() => handleDropdownClick('innovacion')}>Innovación y Tecnología</span>
              <span className="dropdown-item" onClick={() => handleDropdownClick('bienestar')}>Bienestar y Cultura Empresarial</span>
          </div>
        </div>
        
        <Link to="/" className="nav-link" onClick={scrollToFooter}>Contacto</Link>
        {isLoggedIn ? (
          <div className="nav-link logout-icon" onClick={handleLogout}>
            Cerrar sesión
          </div>
        ) : (
          <Link to="/vacantes" className="nav-link">Trabaja con nosotros</Link>
        )}
      </div>
      {showModal && (
        <ModalCloseSesion
          show={showModal}
          onClose={handleCloseModal}
          onConfirm={handleLogout}
        />
      )}
    </nav>
  );
}

export default Navbar;
