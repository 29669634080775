import React, { useState, useEffect } from "react";
import { axiosInstance } from "../AxiosConfig";
import "../styles/Blogpreview.scss";
import { FaArrowLeft, FaChevronLeft, FaChevronRight } from "react-icons/fa"; 
import he from 'he'; 

// Componente Banner1 que muestra la información del blog seleccionado
function Banner1({ image, description, onBack }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = useState(
        window.innerWidth <= 1024 && window.innerWidth > 768
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const decodedDescription = he.decode(description);

    return (
        <div className="banner1Blogpreview">
            <hr className="top-line" />

            <button className="back-button" onClick={onBack}>
                <FaArrowLeft className="back-icon" />
            </button>

            {isMobile || isTablet ? (
                <div className="row1">
                    <div>{image && <img src={image} className="imagenT" alt="Imagen principal del blog" />}</div>
                    <div className="IntroduccionBlog">
                        <div dangerouslySetInnerHTML={{ __html: decodedDescription }} />
                    </div>
                </div>
            ) : (
                <div className="left-column">
                    <div className="Imagentitulo">{image && <img src={image} className="imagenT" alt="Imagen principal del blog" />}</div>
                    <div className="IntroduccionBlog">
                        <div dangerouslySetInnerHTML={{ __html: decodedDescription }} />
                    </div>
                </div>
            )}
        </div>
    );
}

// Componente Banner2 que contiene la lista de blogs
function Banner2({ onBlogSelect }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024 && window.innerWidth > 768);
    const [blogs, setBlogs] = useState([]); 
    const [currentPage, setCurrentPage] = useState(0); 
    const [transitioning, setTransitioning] = useState(false); // Estado para manejar la transición

    const blogsPerPage = isMobile ? 1 : isTablet ? 2 : 3; 

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axiosInstance.get("/api/Blog/GetFilteredBlogs", {
                    params: {
                        habilitado: true,
                    },
                });
                setBlogs(response.data);
            } catch (error) {
                console.error("Error al obtener los blogs:", error);
            }
        };

        fetchBlogs();
    }, []);

    const nextPage = () => {
        const totalPages = Math.ceil(blogs.length / blogsPerPage);
        if (!transitioning) {
            setTransitioning(true);
            setTimeout(() => {
                if (currentPage < totalPages - 1) {
                    setCurrentPage(currentPage + 1);
                } else {
                    setCurrentPage(0); // Volver al principio cuando se alcance la última página
                }
                setTransitioning(false);
            }, 500); // Duración de la animación
        }
    };

    const prevPage = () => {
        if (!transitioning && currentPage > 0) {
            setTransitioning(true);
            setTimeout(() => {
                setCurrentPage(currentPage - 1);
                setTransitioning(false);
            }, 500); // Duración de la animación
        }
    };

    // Calcular el índice de los blogs que se deben mostrar en la página actual
    const indexOfLastBlog = (currentPage + 1) * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    return (
        <div className="OtrosBlogs">
            <h2 className="tituloOtrosBlogs">OTROS <span>ARTÍCULOS</span></h2>

            <div className="carousel-container">
                <button className="carousel-button" onClick={prevPage} disabled={currentPage === 0}>
                    <FaChevronLeft />
                </button>

                <div className={`carousel-content ${transitioning ? 'fade-out' : 'fade-in'}`}>
                    {currentBlogs.map((blog) => (
                        <div className="blog-card" key={blog.idBlog}>
                            <img src={blog.imagen1} alt={blog.tipo} className="blog-image" />
                            <p className="blog-date">
                                Publicado: {new Date(blog.fechaCreacion).toLocaleDateString("es-ES", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                })}
                            </p>
                            <hr className="lineatitulo"/>
                            <h3>{blog.titulo}</h3>
                            <p>{he.decode(blog.descripcion.replace(/<[^>]+>/g, "")).substring(0, 100)}...</p>
                            <button className="blog-button" onClick={() => onBlogSelect(blog)}>
                                LEER MÁS <span>&gt;</span>
                            </button>
                        </div>
                    ))}
                </div>

                <button className="carousel-button" onClick={nextPage}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

// Componente Blogpreview
function Blogpreview({ blog, onBack }) {
    const [selectedBlog, setSelectedBlog] = useState(null); // Estado para manejar el blog seleccionado

    const handleBlogSelect = (blog) => {
        setSelectedBlog(blog); // Actualiza el estado con el blog seleccionado desde Banner2
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Animación suave
          });
    };

    // Función para manejar el "VOLVER"
    const handleBack = () => {
        if (selectedBlog) {
            setSelectedBlog(null); // Si hay un blog seleccionado, resetea el estado
            
        } else {
            onBack(); // Si no hay blog seleccionado, vuelve al componente principal
        }
    };

    return (
        <>
            {/* Si hay un blog seleccionado en Banner2, mostrar ese, de lo contrario mostrar el que viene del componente principal */}
            <Banner1
                image={selectedBlog ? selectedBlog.imagen1 : blog.imagen1}
                description={selectedBlog ? selectedBlog.descripcion : blog.descripcion}
                onBack={handleBack} // Usar la nueva función handleBack para el botón "VOLVER"
            />
            <Banner2 onBlogSelect={handleBlogSelect} />
        </>
    );
}

export default Blogpreview;
