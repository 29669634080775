// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BurgerMenu {
  background-color: transparent !important;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  z-index: 5;
}
.BurgerMenu .icon {
  width: 50%;
  font-size: 24px;
  color: white;
}
.BurgerMenu .navbar {
  top: 100%;
  left: 0;
  background-color: black;
  display: none;
  width: 100%;
}
.BurgerMenu .navbar.show {
  display: block;
}
.BurgerMenu .navbar .nav-link {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
  padding: 10px;
}
.BurgerMenu .navbar .dropdown {
  position: relative;
}
.BurgerMenu .navbar .blog-dropdown {
  cursor: pointer;
}
.BurgerMenu .navbar .dropdown-menu {
  display: none;
  background-color: black;
  padding: 10px;
  border: 1px solid #ccc;
  position: relative;
  top: 0;
  left: 0;
}
.BurgerMenu .navbar .dropdown-menu.show {
  display: block;
}
.BurgerMenu .navbar .dropdown-menu .dropdown-item {
  color: black;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 11px !important;
}
.BurgerMenu .navbar .dropdown-menu .dropdown-item:hover {
  background-color: #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/styles/BurgerButton.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;AACF;AACE;EACE,UAAA;EACA,eAAA;EACA,YAAA;AACJ;AAEE;EACE,SAAA;EACA,OAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAGI;EACE,YAAA;EACA,qBAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;AADN;AAII;EACE,kBAAA;AAFN;AAKI;EACE,eAAA;AAHN;AAMI;EACE,aAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AAJN;AAOI;EACE,cAAA;AALN;AAQI;EACE,YAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;EACA,0BAAA;AANN;AAUI;EACE,yBAAA;AARN","sourcesContent":[".BurgerMenu {\n  background-color: transparent !important;\n  cursor: pointer;\n  padding: 10px;\n  position: absolute;\n  z-index: 5;\n\n  .icon {\n    width: 50%;\n    font-size: 24px;\n    color: white;\n  }\n\n  .navbar {\n    top: 100%;\n    left: 0;\n    background-color: black;\n    display: none; // Ocultamos el menú por defecto\n    width: 100%;\n\n    &.show {\n      display: block; // Mostrar el menú cuando se aplique la clase 'show'\n    }\n\n    .nav-link {\n      color: white;\n      text-decoration: none;\n      margin-bottom: 10px;\n      display: block;\n      padding: 10px;\n    }\n\n    .dropdown {\n      position: relative;\n    }\n\n    .blog-dropdown {\n      cursor: pointer;\n    }\n\n    .dropdown-menu {\n      display: none; // Ocultamos el submenú por defecto\n      background-color: black;\n      padding: 10px;\n      border: 1px solid #ccc;\n      position: relative;\n      top: 0;\n      left: 0;\n    }\n\n    .dropdown-menu.show {\n      display: block; // Mostrar el submenú cuando se aplique la clase 'show'\n    }\n\n    .dropdown-menu .dropdown-item {\n      color: black;\n      padding: 8px 12px;\n      text-decoration: none;\n      display: block;\n      font-size: 11px !important;\n\n    }\n\n    .dropdown-menu .dropdown-item:hover {\n      background-color: #f0f0f0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
