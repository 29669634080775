import { axiosInstance } from '../AxiosConfig';
import '../styles/VacanciesPage.scss'
import Modal from '../components/Modal';
import { MdLocationOn } from 'react-icons/md';
import { IoTimerSharp } from "react-icons/io5";
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Logo from '../assets/VacanciesPage/TRABAJA.png'
import VacancyDetailsModal from '../components/VacancyDetailsModal'
import { FaBriefcase, FaCheck, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

function VacanciesPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [applied, setApplied] = useState(false);
    const [vacancies, setVacancies] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [formFields, setFormFields] = useState({ city: '', searchKeyword: '' });
    const [filterVacancies, setFilterVacancies] = useState([]);
    const [activeSection, setActiveSection] = useState('offers');
    const [selectedVacancy, setSelectedVacancy] = useState(null);
    const [appliedVacanciesIds, setAppliedVacanciesIds] = useState([]);
    const [selectOptions, setSelectOptions] = useState({ ciudades: [] });
    const [showVacancyDetailsModal, setShowVacancyDetailsModal] = useState(false);

    const handleCityChange = (e) => {
        const selectedCityName = e.target.value;
        setFormFields(prev => ({ ...prev, city: selectedCityName }));
    };

    const handleSectionClick = (section) => {
        setActiveSection(section);
        if (section === 'offers') {
            const markedVacancies = vacancies.map(vacancy => ({
                ...vacancy,
                isApplied: appliedVacanciesIds.includes(vacancy.idVacante)
            }));
            setFilterVacancies(markedVacancies);
            setSelectedVacancy(markedVacancies.length > 0 ? markedVacancies[0] : null);
        } else if (section === 'applications' && user) {
            axiosInstance.get(`/api/Vacantes/GetVacanciesCandidate`, {
                params: { idUsuario: user.userid }
            })
                .then(response => {
                    const appliedVacancies = response.data;
                    setAppliedVacanciesIds(appliedVacancies.map(v => v.idVacante));
                    setFilterVacancies(appliedVacancies);
                    setSelectedVacancy(appliedVacancies.length > 0 ? appliedVacancies[0] : null);
                })
                .catch(error => {
                    console.error("Error al obtener las postulaciones del usuario:", error);
                    setSelectedVacancy(null);
                    setFilterVacancies([]);
                });
        } else {
            setFilterVacancies(vacancies);
            setSelectedVacancy(vacancies.length > 0 ? vacancies[0] : null);
        }
    };

    const clearFilters = () => {
        setFormFields({ city: '', searchKeyword: '' });
        setFilterVacancies(vacancies);
    };

    const handleSearchKeywordChange = (e) => {
        const searchKeyword = e.target.value;
        setFormFields(prev => ({ ...prev, searchKeyword }));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const normalizeText = (text) => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        const filtered = vacancies.filter(vacancy =>
            (formFields.city ? normalizeText(vacancy.ciudadVacante) === normalizeText(formFields.city) : true) &&
            (formFields.searchKeyword ? normalizeText(vacancy.nombreVacante).includes(normalizeText(formFields.searchKeyword)) : true)
        );
        setFilterVacancies(filtered.length > 0 ? filtered : []);
    };

    useEffect(() => {
        const checkUserAndVacancy = async () => {
            const userData = localStorage.getItem('userData');
            if (userData) {
                const parsedUser = JSON.parse(userData);
                setUser(parsedUser);
                try {
                    const response = await axiosInstance.get(`/api/Vacantes/GetVacanciesCandidate`, {
                        params: { idUsuario: parsedUser.userid }
                    });
                    const appliedVacancies = response.data;
                    setAppliedVacanciesIds(appliedVacancies.map(v => v.idVacante));
                } catch (error) {
                    console.error("Error al obtener las postulaciones del usuario:", error);
                }
            }

            const fetchApiList = async () => {
                
                try {
                    const params ={
                        estadoVacante: 149 || undefined,
                    };
                    
                    const ciudadesResponse = await axiosInstance.get('/api/List/GetListCities');
                    const idsFiltrados = [169, 1100, 135];
                    const filteredCities = ciudadesResponse.data.filter(city => idsFiltrados.includes(parseInt(city.id_ciudad)));
                    setSelectOptions({ ciudades: filteredCities });

                    const vacanciesResponse = await axiosInstance.get('/api/Vacantes/GetListVacancies',{params});
                    setVacancies(vacanciesResponse.data);
                    setFilterVacancies(vacanciesResponse.data);
                    if (vacanciesResponse.data.length > 0) {
                        setSelectedVacancy(vacanciesResponse.data[0]);
                    }

                    const storedVacancyId = sessionStorage.getItem('selectedVacancyId');
                    if (storedVacancyId && location.state?.fromLogin) {
                        sessionStorage.removeItem('selectedVacancyId');
                        const storedVacancy = vacanciesResponse.data.find(v => v.idVacante === parseInt(storedVacancyId));
                        if (storedVacancy) {
                            setSelectedVacancy(storedVacancy);
                            handleSubmitApplication(storedVacancy);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching API data:', error);
                }
            };

            await fetchApiList();
        };

        checkUserAndVacancy();
    }, [location]);

    function timeSince(date) {
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);
        let interval = seconds / 31536000;
        if (interval > 1) return Math.floor(interval) + " años";
        interval = seconds / 2592000;
        if (interval > 1) return Math.floor(interval) + " meses";
        interval = seconds / 86400;
        if (interval > 1) return Math.floor(interval) + " días";
        interval = seconds / 3600;
        if (interval > 1) return Math.floor(interval) + " horas";
        interval = seconds / 60;
        if (interval > 1) return Math.floor(interval) + " minutos";
        return Math.floor(seconds) + " segundos";
    }

    const handleSelectVacancy = (vacancy) => {
        setSelectedVacancy(vacancy);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalContent({});
        setShowVacancyDetailsModal(false);
    };

    function handleSubmitApplication(vacancy = selectedVacancy) {
        if (!user) {
            sessionStorage.setItem('selectedVacancyId', vacancy.idVacante);
            navigate('/login', { state: { from: '/vacantes' } });
            return;
        }

        if (!vacancy) {
            console.error('La información de la vacante no está disponible');
            return;
        }

        const applicationData = {
            idVacante: vacancy.idVacante,
            idUsuario: user.userid,
            etapaVacante: 87,
            estadoVacante: 149
        };

        axiosInstance.post('/api/Vacantes/CreateVacancyCandidate', applicationData)
            .then(response => {
                if (response.data === true) {
                    setApplied(true);
                    setAppliedVacanciesIds(prev => [...prev, vacancy.idVacante]);
                    setModalContent({
                        icon: <FaCheckCircle className='iconos-modales' />,
                        title: "Postulación exitosa",
                        message: "¡Felicidades, te has postulado a esta vacante!",
                        onConfirm: handleCloseModal
                    });
                } else {
                    setModalContent({
                        icon: <FaExclamationCircle className='iconos-modales' />,
                        title: "Ups!",
                        message: "Hubo un error al momento de postularse a esta vacante.",
                        onConfirm: handleCloseModal
                    });
                }
                setShowModal(true);
            })
            .catch(error => {
                console.error("Error al realizar la solicitud:", error);
                setModalContent({
                    icon: <FaExclamationCircle className='iconos-modales' />,
                    title: "Ups!",
                    message: "Hubo un error al momento de postularse a esta vacante.",
                    onConfirm: handleCloseModal
                });
                setShowModal(true);
            });
    }

    const handleSelectVacancyMobile = (vacancy) => {
        setSelectedVacancy(vacancy);
        setShowVacancyDetailsModal(true);
    };

    const isMobile = window.innerWidth <= 768;

    const renderDesktopLayout = () => (
        <>
            <div className='Contenedor-vacantes'>
                <div className='Banner-Vacante'>
                    <div className={`Left ${activeSection === 'applications' && filterVacancies.length === 0 ? 'Left-no-postulaciones' : ''}`}>
                        <img src={Logo} className="Logo" alt="Imagen" />
                        <p>En ALBA Holding, convertimos la innovación y la creatividad en elementos fundamentales de nuestra cultura y estilo de vida</p>
                        <p>Por eso, queremos encontrar personas convenidas de que somos nosotros, el talento de la organización, los encargados de lograr lo que nos proponemos</p>
                    </div>
                </div>
                <div className={`Filtros-vacantes ${activeSection === 'applications' && filterVacancies.length === 0 ? 'Filtros-vacantes-no-postulaciones' : ''}`}>
                    <h1>CONOCE NUESTRAS <br /><span className='variante-titulo'>VACANTES DISPONIBLES</span></h1>
                    <form className="form-inline mt-4">
                        <div className="form-group">
                            <label className="form-label">Palabra clave</label>
                            <input
                                type="text"
                                id="selectPalabra"
                                className="form-control"
                                placeholder="Ingresa el nombre de una vacante"
                                value={formFields.searchKeyword}
                                onChange={handleSearchKeywordChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Ciudad</label>
                            <select
                                name="city"
                                value={formFields.city}
                                onChange={handleCityChange}
                                required
                                className="form-control"
                                id="selectCiudad"
                            >
                                <option value=''>Selecciona la ciudad</option>
                                {selectOptions.ciudades.map(city => (
                                    <option key={city.id_ciudad} value={city.nombreCiudad}>{city.nombreCiudad}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mt-2">
                            <button type="submit" className="btn btn-primary mt-4" onClick={handleSearch}>Buscar</button>
                        </div>
                        <div className="form-group mt-2">
                            <button type="button" className="btn btn-primary mt-4" onClick={clearFilters}>Limpiar</button>
                        </div>
                    </form>
                </div>
                <div className='Visual-vacantes'>
                    <div className='spacer'>
                        <div className='left-column'>
                            <div className='navigation'>
                                <div className='nav-options'>
                                    <div
                                        className={`nav-option ${activeSection === 'offers' ? 'active' : ''}`}
                                        onClick={() => handleSectionClick('offers')}
                                    >
                                        Ver ofertas
                                    </div>
                                    <div
                                        className={`nav-option ${activeSection === 'applications' ? 'active' : ''}`}
                                        onClick={() => handleSectionClick('applications')}
                                    >
                                        Mis postulaciones
                                    </div>
                                </div>
                            </div>
                            <div className='cartas'>
                                {activeSection === 'applications' && filterVacancies.length === 0 ? (
                                    <div className='vacante-card '>
                                        <div className='card-content'>
                                            <h2 className='card-title mt-3'>¡No hay Postulaciones todavia!</h2>
                                            <p className='card-info'>Debes primero aplicar para alguna vacante </p>
                                        </div>
                                    </div>
                                ) : filterVacancies.map((vacancy, index) => (
                                    <div key={index} className={`vacante-card ${selectedVacancy && selectedVacancy.idVacante === vacancy.idVacante ? 'selected' : ''}`}
                                        onClick={() => handleSelectVacancy(vacancy)}>
                                        <div className='card-content'>
                                            <h2 className='card-title mt-3'>{vacancy.nombreVacante}</h2>
                                            <p className='card-info'>Publicado hace {timeSince(vacancy.fechaInicioVacante)}</p>
                                            <div className='card-info'>
                                                <span className='schedule me-3'><IoTimerSharp className='icono-vacante' />{vacancy.horario}</span>
                                                <span className='location me-3'><MdLocationOn className='icono-vacante' />{vacancy.ciudadVacante}</span>
                                                <span className='area'><FaBriefcase className='icono-vacante' />{vacancy.areaEmpresa}</span>
                                            </div>
                                            <button className='apply-button' onClick={() => handleSelectVacancy(vacancy)}>Ver vacante</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='info-vacante card'>
                            {selectedVacancy ? (
                                <div className='info-card card-body'>
                                    <h1 className='card-title'>{selectedVacancy.nombreVacante}</h1>
                                    <p className='card-text mb-4'>Cod. {selectedVacancy.idVacante}</p>
                                    <div className='card-info d-flex flex-nowrap justify-content-between g-2'>
                                        <div className='d-flex align-items-center '>
                                            <span className='schedule me-4'><IoTimerSharp className='icono-vacante' />{selectedVacancy.horario}</span>
                                            <span className='location me-4'><MdLocationOn className='icono-vacante' />{selectedVacancy.ciudadVacante}</span>
                                            <span className='area'><FaBriefcase className='icono-vacante' />{selectedVacancy.areaEmpresa}</span>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <span className='time'>Publicado hace {timeSince(selectedVacancy.fechaInicioVacante)}</span>
                                        </div>
                                    </div>
                                    <h3 className='card-subtitle mb-2'>Descripción General</h3>
                                    <p className='card-text card-descripcion mb-4'>{selectedVacancy.descripcionVacante}</p>
                                    <p className='card-text'><span className='span-card'>Horario:</span> {selectedVacancy.horario}</p>
                                    <p className='card-text'><span className='span-card'>Lugar de trabajo:</span> {selectedVacancy.ciudadVacante}</p>
                                    <p className='card-text'><span className='span-card'>Tipo de contrato:</span> {selectedVacancy.tipoContrato}</p>
                                    <p className='card-text'><span className='span-card'>Salario: </span> ${selectedVacancy.salario} con todas las prestaciones de ley + recargos y horas extras.</p>
                                    <p className='card-text'><span className='span-card'>Pago:</span> {selectedVacancy.periodicidadPago}</p>
                                    <p className='card-text mt-4'>Si cumples con el perfil ¡Postúlate!</p>
                                    <h4 className='card-subtitle mb-2'>Requerimientos</h4>
                                    <p className='card-text'><span className='span-card'><FaCheck className='icono-requerimientos me-2' />Educación Mínima:</span> {selectedVacancy.formacionAcademica}</p>
                                    <p className='card-text'><span className='span-card'><FaCheck className='icono-requerimientos me-2' />Experiencia requerida:</span> {selectedVacancy.tiempoExperiencia}</p>
                                    <p className='card-text'><span className='span-card'><FaCheck className='icono-requerimientos me-2' />Edad:</span> {selectedVacancy.rangoEdad}</p>
                                    <p className='card-text'><span className='span-card'><FaCheck className='icono-requerimientos me-2' />Debe residir en:</span> {selectedVacancy.ciudadVacante}</p>
                                    {
                                        selectedVacancy && !appliedVacanciesIds.includes(selectedVacancy.idVacante) && (
                                            <button
                                                className='apply-card-button mb-1 mt-4'
                                                onClick={() => handleSubmitApplication(selectedVacancy)}
                                                disabled={applied}
                                                style={{ display: applied ? 'none' : 'block' }}
                                            >
                                                {applied ? 'Ya te has postulado' : 'Postularme'}
                                            </button>
                                        )
                                    }
                                </div>
                            ) : activeSection === 'applications' ? (
                                <div className='info-card card-body'>
                                    <h1 className='card-title'>Aún no has postulado</h1>
                                    <p className='card-text'>Primero debes aplicar para alguna vacante.</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const renderMobileLayout = () => (
        <>
            <div className='Contenedor-vacantes'>
                <div className='Banner-Vacante'>
                    <div className='Left'>
                        <img src={Logo} className="Logo" alt="Imagen" />
                        <p><span>En ALBA Holding vivimos para dejar la huella,</span> por eso buscamos atraer el mejor talento humano de nuestro país.</p>
                    </div>
                </div>
                <div className='Filtros-Mobile'>
                    <h1>CONOCE NUESTRAS <br /><span className='variante-titulo'>VACANTES DISPONIBLES</span></h1>
                    <form className="form-inline mt-3">
                        <div className="form-group">
                            <label className="form-label">Palabra clave</label>
                            <input
                                type="text"
                                id="selectPalabra"
                                className="form-control"
                                placeholder="Ingresa el nombre de una vacante"
                                value={formFields.searchKeyword}
                                onChange={handleSearchKeywordChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Ciudad</label>
                            <select
                                name="city"
                                value={formFields.city}
                                onChange={handleCityChange}
                                required
                                className="form-control"
                                id="selectCiudad"
                            >
                                <option value=''>Selecciona la ciudad</option>
                                {selectOptions.ciudades.map(city => (
                                    <option key={city.id_ciudad} value={city.nombreCiudad}>{city.nombreCiudad}</option>
                                ))}
                            </select>
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className="form-group ">
                                <button type="submit" className="btn btn-primary" onClick={handleSearch}>Buscar</button>
                            </div>
                            <div className="form-group">
                                <button type="button" className="btn btn-primary mt-2" onClick={clearFilters}>Limpiar</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='Vacantes'>
                    <div className='spacer' />
                    <div className={`navigation ${activeSection === 'offers' ? 'offers' : 'applications'}`}>
                        <div className='nav-options'>
                            <div
                                className={`nav-option ${activeSection === 'offers' ? 'active' : ''}`}
                                onClick={() => handleSectionClick('offers')}
                            >
                                Ver ofertas
                            </div>
                            <div
                                className={`nav-option ${activeSection === 'applications' ? 'active' : ''}`}
                                onClick={() => handleSectionClick('applications')}
                            >
                                Mis postulaciones
                            </div>
                        </div>
                    </div>
                    <div className='cartas'>
                        {activeSection === 'applications' && filterVacancies.length === 0 ? (
                            <div className='vacante-card '>
                                <div className='card-content'>
                                    <h2 className='card-title mt-3'>¡No hay Postulaciones todavia!</h2>
                                    <p className='card-info'>Debes primero aplicar para alguna vacante </p>
                                </div>
                            </div>
                        ) : filterVacancies.map((vacancy, index) => (
                            <div key={index} className={`vacante-card ${selectedVacancy && selectedVacancy.idVacante === vacancy.idVacante ? 'selected' : ''}`}
                                onClick={() => handleSelectVacancyMobile(vacancy)}>
                                <div className='card-content'>
                                    <h2 className='card-title mt-3'>{vacancy.nombreVacante}</h2>
                                    <p className='card-info'>Publicado hace {timeSince(vacancy.fechaInicioVacante)}</p>
                                    <div className='card-info'>
                                        <span className='schedule me-3'><IoTimerSharp className='icono-vacante' />{vacancy.horario}</span>
                                        <span className='location me-3'><MdLocationOn className='icono-vacante' />{vacancy.ciudadVacante}</span>
                                        <span className='area'><FaBriefcase className='icono-vacante' />{vacancy.areaEmpresa}</span>
                                    </div>
                                    <button className='apply-button' onClick={() => handleSelectVacancyMobile(vacancy)}>Ver vacante</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <>
            {isMobile ? renderMobileLayout() : renderDesktopLayout()}
            {showVacancyDetailsModal && (
                <VacancyDetailsModal
                    vacancy={selectedVacancy}
                    onClose={handleCloseModal}
                    onApply={() => handleSubmitApplication(selectedVacancy)}
                    alreadyApplied={appliedVacanciesIds.includes(selectedVacancy?.idVacante)}
                />
            )}
            {showModal && (
                <Modal
                    show={showModal}
                    onClose={handleCloseModal}
                    icon={modalContent.icon}
                    title={modalContent.title}
                    redirectUrl={modalContent.redirectUrl}
                    message={modalContent.message}
                    onConfirm={modalContent.onConfirm}
                />
            )}
        </>
    );
}

export default VacanciesPage;