import React, { useState, useEffect } from 'react';
import '../styles/SuccessStories.scss'; // Importa el archivo CSS para los estilos
import logo from "../assets/Successstories/Links/BANNER-CASOS-DE-ÉXITO.jpg";
import titulo from "../assets/Successstories/Links/CASOS-DE-ÉXITO-TITULO.png";
import Imgcata from "../assets/Successstories/Links/CATA-1.png";
import Imglis from "../assets/Successstories/Links/LISDELIA-1.png";
import Imgpaola from "../assets/Successstories/Links/PAOLA-1.png";
import Imgmiguel from "../assets/Successstories/Links/MIGUEL-1.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ImgCatainf1 from "../assets/Successstories/Links/MicrosoftTeams-image (24) 2.png";
import ImgCatainf2 from "../assets/Successstories/Links/MKT17112022AQF3B-2.jpg";
import ImgLisinf1 from "../assets/Successstories/Links/DSC_7175-2.jpg";
import ImgLisinf2 from "../assets/Successstories/Links/_LBA0177-t.jpg";
import ImgPaoinf1 from "../assets/Successstories/Links/MKT1592021JLT6F15.jpg";
import ImgPaoinf2 from "../assets/Successstories/Links/_LBA0089.jpg";
import ImgMiginf1 from "../assets/Successstories/Links/IMG_8582.jpg";
import ImgMiginf2 from "../assets/Successstories/Links/MKT1592021DV6F11.jpg";
function Banner1() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Succes1">
      {isMobile || isTablet ? (
        <div className="row1">
          <img src={titulo} className="imagen1" alt="Imagen" />
          <div className="IntroduccionBlog">
            <p>
              ¡Definimos <span>nuestro </span>
              <span>propósito</span> al crear <br /> empresas diseñadas para
              alcanzar <span>el éxito!</span>
            </p>
          </div>
        </div>
      ) : (
        <div className="left-column">
          <div className="IntroduccionBlog">
            <img src={titulo} className="titulo"></img>
            <p>
              <strong>De la pasión al logro,</strong>
              descubre cómo <br /> nuestros talentos crecen y se <br />
              superan junto a nosotros
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

function Banner2() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStory, setSelectedStory] = useState(null); // Estado para la historia seleccionada
  const blogsPerPage = 4; // Número de blogs por página (igual al total ahora)

  const blogs = [
    {
      id: 'cata',
      title: "UNA HISTORIA DE LIDERAZGO QUE TRANSFORMA",
      description:
        "Cuando hablamos de crecimiento profesional, pocos ejemplos son tan inspiradores como el de Catalina, o como muchos la llaman cariñosamente, Cata. Con tan solo 23 años, Cata ha construido una carrera impresionante en Alba Holding...",
      img: Imgcata,
      className: "Ccatalina",
    },
    {
      id: 'lis',
      title: "UN VIAJE DE TRANSFORMACIÓN Y PERSEVERANCIA",
      description:
        "Cuando pensamos en historias inspiradoras, pocas pueden compararse con la de Lisdelia Restrepo Cortez. De ser parte del equipo de servicios generales a convertirse en una Monitora clave en Alba Holding, Lisdelia ha demostrado que, con esfuerzo...",
      img: Imglis,
      className: "CLisbelia",
    },
    {
      id: 'pao',
      title: "EVOLUCIÓN Y CRECIMIENTO CONSTANTE",
      description:
        "Si hay una palabra que define el camino de Angie Paola Sánchez en Alba Holding, esa es evolución. Desde su inicio como monitora hasta convertirse en Analista de Entrenamiento, Paola ha demostrado que los desafíos no son más que oportunidades para...",
      img: Imgpaola,
      className: "CPaola",
    },
    {
      id: 'mig',
      title: "UN CAMINO DE ÉXITO Y TRANSFORMACIÓN",
      description:
        "La historia de Miguel Ángel Londoño, Coordinador de OnlyFans en Alba Holding, es una verdadera inspiración para todos aquellos que buscan superarse, reinventarse y seguir creciendo.Desde sus inicios como practicante hasta su actual rol de liderazgo...",
      img: Imgmiguel,
      className: "CMiguel",
    },
  ];

  const totalPages = Math.ceil(blogs.length / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const nextPage = () => {
    changePage(currentPage + 1);
  };

  const prevPage = () => {
    changePage(currentPage - 1);
  };
  const goToNextStory = () => {
    const currentIndex = blogs.findIndex((blog) => blog.id === selectedStory);
    const nextIndex = (currentIndex + 1) % blogs.length; // Ciclo
    setSelectedStory(blogs[nextIndex].id);

     // Desplazarse hacia la parte superior
     window.scrollTo({
    top: 0,
    behavior: 'smooth', // Agrega animación al desplazamiento
  });
  };

  const goToBackStory = () => {
    const currentIndex = blogs.findIndex((blog) => blog.id === selectedStory);
    const backIndex = currentIndex === 0 ? blogs.length - 1 : currentIndex - 1;
    setSelectedStory(blogs[backIndex].id);

     // Desplazarse hacia la parte superior
    window.scrollTo({
    top: 0,
    behavior: 'smooth', // Agrega animación al desplazamiento
  });
  };

  const renderStoryDetails = () => {
    switch (selectedStory) {
      case 'cata':
        return (
          <div className="story-details">
            <hr className='hrcards'/>
            <div className='Tituinfo'>
            <p > <strong> <span className='NomCards'>CATALINA -</span> UNA HISTORIA DE <br/> LIDERAZGO QUE TRANSFORMA</strong> </p></div>
            <div className="Imginfo">
            <img src={ImgCatainf1} alt="Catalina" /></div>
            <div className='textinfo1'>
            <p >Cuando hablamos de crecimiento profesional, pocos ejemplos son tan inspiradores como el de <strong>Catalina</strong>, 
            o como muchos la llaman cariñosamente, <strong>Cata</strong>. Con tan solo 23 años, Cata ha construido una carrera impresionante 
            en <strong> Alba Holding</strong>, liderando equipos, enfrentando desafíos y siempre con una sonrisa en el rostro. Hoy,
            te contamos su historia, una verdadera masterclass de cómo transformar cada reto en una oportunidad. </p>
            <p><strong>De la Universidad al Mundo Laboral: Primeros Pasos</strong><br/>Cata no siempre supo que iba a ser una líder nata. Antes 
            de unirse a <strong>Alba Holding</strong>, cursaba la carrera de <strong>Comunicación Social</strong>, mientras exploraba su creatividad 
            en proyectos de marketing. Desde gestionar campañas políticas hasta diseñar piezas gráficas para marcas locales,Catalina nunca dejó que 
            los límites la frenaran. “Hoy en día veo esas piezas y mejor cierro los ojos”, confiesa entre risas, pero todos los que conocemos su historia 
            sabemos que esos primeros pasos la llevaron a donde está ahora.</p>
            <p>Un día, mientras navegaba en una plataforma buscando prácticas profesionales, encontró la oportunidad que cambiaría su vida. Sin pensarlo dos veces, 
            aplicó para el puesto de<strong> Community Manager</strong> en Alba y, tras varias entrevistas, consiguió su primer empleo formal. “Recuerdo que, desde la primera videollamada, 
            supe que quería estar aquí”, comenta Cata. ¡Y vaya que lo logró! </p>
            <p><strong>Una Lider que Sabe de Todo un Poco</strong><br/>Pero Cata no se quedó solo en el rol de <strong>Community Manager</strong>. En Alba, se transformó en todo lo que 
            te puedas imaginar: actriz, modelo, locutora, tiktoker, ¡hasta influencer! Cada reto la ayudó a descubrir nuevas facetas de sí misma. Pero no todo fue fácil; 
            a sus 20 años, adaptarse al mundo laboral fue su primer gran desafío. "Era un caos organizarme",recuerda. Sin embargo, con determinación, organización y 
            mucha <strong>planificación estratégica</strong>, logró superar cada obstáculo y demostrar que podía con todo. </p>
            </div>
            <div className='textinfo2'>
            <p>Una de las historias que más recuerda con cariño fue el día en que la carga de trabajo parecía imposible. Con una montaña de videos que necesitaban grabarse, 
            enfrentó el reto sin miedo, agendando todo en una semana. “Aunque parecía que el mundo se me venía encima, lo logramos con esfuerzo y trabajo en equipo”.
            Ese episodio no solo fortaleció su carácter, sino que también le demostró que, con determinación, cualquier objetivo es posible. </p>
            <p><strong>El camino del Liderazgo</strong><br/>La confianza que los líderes de Alba depositaron en Cata fue crucial para que asumiera un nuevo reto: liderar el 
            proyecto <strong>Upperside</strong>, una marca de moda en crecimiento. A pesar de los desafíos iniciales, tener que conformar una estructura, aprender de temas financieros, y mucho 
            más, Catalina se arremangó las mangas y se puso manos a la obra. “Hoy,tengo un equipo que responde a una cultura que hemos construido juntos, trabajando de 
            manera coherente y enfocada”, comenta con orgullo. </p>
            <p>Lo que más la motivó fue poder involucrarse en un área que siempre le apasionó: la moda. “Ver un diseño desde su concepción hasta verlo comercializado es 
            increíble”, dice Cata, mostrando su entusiasmo por lo que hace. No solo aprendió sobre liderazgo y gestión, sino que también reforzó su <strong>paciencia, empatía y 
            su capacidad de escucha activa</strong>. Estas habilidades no solo han sido claves en su crecimiento, sino que también han impactado de manera positiva en todo su 
            equipo. </p>

          <div className="Imginfo2"><img  src={ImgCatainf2} alt="Catalina"/> </div> 
            <div className='textinfo3'>
            <p><strong>¡140.000 Seguidores en una Semana!</strong><br/>Uno de los logros más impresionantes de Cata fue llevar a Alba de 86.000 a 140.000 seguidores en Instagram
            ¡en tan solo una semana! Un hito que marcó un antes y un después en su carrera. “Fue un momento inmensamente satisfactorio”, recuerda con una sonrisa. Y no es para menos
            , su trabajo y dedicación no solo han impulsado las redes sociales de la empresa, sino que también han dejado huella en cada proyecto en el que ha trabajado.  </p>
            <p><strong>Un Liderazgo Inspirador</strong><br/>Cuando le preguntamos a Cata cuál es la clave de su éxito, su respuesta es clara: las personas. Para ella, construir
             relaciones sólidas y generar confianza con su equipo es lo más importante. “Escuchar activamente y tener una comunicación clara son fundamentales”, asegura. Además,
             Cata siempre se enfoca en <strong>reconocer los logros</strong> de su equipo y celebrar cada avance, creando un ambiente de trabajo colaborativo y lleno de aprendizaje continuo. </p>
             <p>Cata también nos habla de su mentor, <strong>Camilo</strong>, de quien aprendió la importancia de la acción. “Me enseñó que no basta con saber, hay que hacer. Las ideas se transforman en el camino hasta volverse brillantes”,
            comenta. Y es ese espíritu de hacer que ha impulsado a Cata a convertirse en la líder que es hoy. </p>
            <p><strong>Mirando al Futuro</strong><br/>Cata no se detiene aquí. Su visión es clara: <strong>Upperside</strong> será una marca reconocida no solo a nivel nacional, sino también internacionalmente. “Veo a Upperside como una referencia en el mundo 
            de la moda,expandiéndose con colecciones innovadoras y detalles de otro planeta”, dice con confianza. Además, sueña con seguir aprendiendo y crecer en el 
            área del <strong>marketing digital</strong> y hasta estudiar <strong>diseño de modas</strong> en el futuro. </p>
            </div>
            <div className='textinfo4'>
            <p><strong>Una Trayectoria Transformadora</strong><br/>Si Cata pudiera describir su camino en una sola palabra, sería <strong>“transformadora”</strong>. La Catalina que entró por primera vez a Alba Holding ya no es la misma. Hoy, es una líder que ha aprendido de cada reto, 
            que sigue creciendo y que inspira a todos los que la rodean con su pasión, dedicación y carisma. </p>
            <p>En resumen, la historia de <strong>Catalina</strong> es un claro ejemplo de cómo el compromiso, la versatilidad y el liderazgo pueden transformar una carrera.Desde community manager 
            hasta líder de un equipo exitoso, Cata ha demostrado que los límites solo están en nuestra mente. Y en Alba Holding, ¡el cielo es el límite! </p>
            </div>

            </div>
          </div>
        );
      case 'lis':
        return (
          <div className="story-details">
             <hr className='hrcards'/>
           <div className='Tituinfo'> <p > <strong> <span className='NomCards'>LISDELIA -</span> UN VIAJE DE TRANSFORMACIÓN <br/> Y PERSEVERANCIA EN ALBA HOLDING</strong> </p></div>
            <div className="Imginfo3"> <img   src={ImgLisinf1} alt="Catalina" /></div>
            <div className='textinfo5'>
            <p >Cuando pensamos en historias inspiradoras, pocas pueden compararse con la de <strong>Lisdelia Restrepo Cortez</strong>. De ser parte del equipo de <strong>servicios generales</strong> a convertirse en una <strong>Monitora</strong> clave en <strong>Alba Holding</strong>, 
                Lisdelia ha demostrado que, con esfuerzo, confianza en uno mismo y una mentalidad positiva, se puede lograr cualquier cosa. Esta es su historia, una de perseverancia, crecimiento y 
                dedicación a lo largo de su trayecto en la empresa.  </p>
            <p><strong>De los Primeros Pasos a un Nuevo Comienzo</strong><br/>Antes de unirse a Alba, Lisdelia ya tenía experiencia trabajando en atención al cliente en un restaurante. Su vida laboral estaba marcada por la interacción constante con personas, pero no fue hasta que su hermano 
            la invitó a conocer<strong> Alba Holding</strong> que descubrió una oportunidad que cambiaría su vida. </p>
            <p>Cuando comenzó en la empresa, su rol era en servicios generales, pero rápidamente se sintió como en casa. <strong>"Lo bien que me hacían sentir como persona, sentí que era mi segundo hogar,"</strong> recuerda con una sonrisa. 
                Esta sensación de pertenencia fue lo que la impulsó a aceptar nuevos retos y buscar su crecimiento dentro de la compañía.  </p>
            <p><strong>El Miedo al Cambio y la Superación </strong><br/>Como en cualquier historia de éxito, Lisdelia enfrentó desafíos. Su mayor reto fue cuando le ofrecieron el puesto de <strong>Monitora</strong>. Al principio, el miedo a fallar le rondaba la cabeza.<strong> "Tenía miedo de fallarle a
             quienes creían en mí y, peor aún, de fallarme a mí misma,"</strong> confiesa. Sin embargo, decidió confiar en sus capacidades y adoptar una actitud positiva.</p>
             <p>A lo largo del tiempo, Lisdelia no solo superó el miedo, sino que demostró que tenía lo necesario para destacar en su nuevo rol. Aprendió nuevas habilidades, como manejar sistemas y páginas web, pero lo más importante fue aprender a gestionar las diferentes 
                emociones de las personas a su cargo.<strong> "Mi desafío fue ganarme el respeto y la confianza de cada persona," </strong>dice con orgullo, y lo superó al aplicar una de sus cualidades más valiosas:<strong> la empatía.</strong> </p>
            </div>
            <div className='textinfo2'>
            <p><strong>Cambios que ha vivido Lisdelia</strong><br/>El trabajo en <strong>Alba Holding</strong> no solo transformó su carrera, sino también su vida personal.
             <strong>"Cuando llegué a Alba, mi vida personal y económica mejoraron,"</strong>  comparte.  </p>
            <p>Con cada paso que daba dentro de la empresa, Lisdelia se sentía más segura y realizada. Su mayor logro no fue solo profesional, sino también personal:
            mejorar como persona cada día, desarrollando sus habilidades y fortaleciendo sus relaciones con sus colegas.  </p>
            <p>Además de su crecimiento personal, Lisdelia ha impactado positivamente en su equipo.<strong>"Siempre doy lo mejor de mí, trabajando en equipo y apoyando a los demás," 
            </strong>  comenta. Y esa actitud colaborativa ha sido fundamental para el éxito de las personas a su alrededor. </p>
            <p><strong>Inspirada por Grandes Líderes </strong><br/>Como todo gran profesional, Lisdelia ha tenido figuras clave en su vida que la han apoyado y motivado. 
            Fuera de la empresa, destaca a <strong>Daniela Alba</strong>, quien ha sido un pilar de apoyo constante. <strong>Dentro de Alba Holding</strong>, menciona a <strong>Camilo y John Alba</strong> como las personas que más creyeron en ella, 
            brindándole oportunidades y confiando en su potencial. Su influencia ha sido crucial para que Lisdelia se sienta segura de cada paso que ha dado. </p>
           <div className="Imginfo2"><img  src={ImgLisinf2} alt="Catalina"/></div> 
            <div className='textinfo6'>
            <p><strong>Más Allá del Trabajo</strong><br/>Aunque dedica mucho de su tiempo y esfuerzo al trabajo, Lisdelia sabe que mantener un equilibrio entre la vida personal y laboral es clave. Disfruta pasar tiempo con sus hijos, ver series con su hija menor y simplemente 
            relajarse en casa. Estos momentos son los que la recargan para seguir adelante en su vida profesional con la misma energía y entusiasmo.</p>
            <p><strong>Mirando al Futuro </strong><br/>Lisdelia es una mujer de sueños claros y objetivos definidos. En los próximos cinco años se ve a sí misma como una mujer 
            aún más exitosa, habiendo superado nuevos retos dentro de la empresa. En el ámbito personal, tiene metas como terminar de pagar su casa y comprarse una moto Pulsar NS 200. 
            Pero más allá de los bienes materiales, su mayor sueño es seguir mejorando su estabilidad económica y seguir aprendiendo para avanzar en su carrera profesional.</p>
             <p><strong>Consejos para el Éxito </strong><br/>Para aquellos que buscan crecer dentro de la empresa, Lisdelia ofrece un consejo invaluable:<strong> "Siempre ser una persona comprometida con su labor, sin importar el puesto que ocupes. Dar siempre lo mejor de ti, sin esperar nada a cambio."</strong> 
             También destaca la importancia de no depender de la supervisión de un jefe para hacer bien el trabajo, sino de ser proactivos y responsables en cada tarea.  </p>
            <p><strong>Una Trayectoria Llena de Experiencias </strong><br/>Al reflexionar sobre su trayectoria, Lisdelia la describe como una serie de <strong>"buenas y malas experiencias"</strong>, pero cada una de ellas le ha enseñado algo valioso. Su capacidad para aprender de cada situación, mantenerse positiva y nunca rendirse, es lo que la ha llevado hasta donde está hoy.  </p>
            </div>
            </div>
          </div>
        );
      case 'pao':
        return (
          <div className="story-details">
            <hr className='hrcards'/>
            <div className='Tituinfo'><p > <strong> <span className='NomCards'>PAOLA -</span> EVOLUCIÓN CONSTANTE <br/>EN ALBA HOLDING</strong> </p></div>
           <div className="Imginfo"> <img src={ImgPaoinf1} alt="Catalina" /></div> 
            <div className='textinfo1'>
            <p >Si hay una palabra que define el camino de <strong>Angie Paola Sánchez</strong> en <strong>Alba Holding</strong>, esa es <strong>"evolución"</strong>. Desde su inicio como monitora hasta convertirse en <strong>Analista de Entrenamiento</strong> , Paola ha demostrado que los desafíos no son más que oportunidades para crecer, y su historia es un testimonio claro de cómo la 
            perseverancia y el amor por el aprendizaje pueden transformar una carrera. Hoy, te contamos más sobre su recorrido lleno de logros personales y profesionales.  </p>
            <p><strong>De la Producción a la Formación: Un Nuevo Comienzo </strong><br/>Antes de unirse a <strong>Alba Holding</strong>, Paola trabajaba en empresas de producción como operaria de máquinas y empacadora, contratada por agencias temporales. Su vida laboral en ese momento era un vaivén de contratos y empleos temporales. 
            Sin embargo, después de un periodo de desempleo de seis meses, Paola decidió tomar las riendas de su futuro y se inscribió en un programa de tecnología industrial. Con el apoyo de su familia y su esposo, Paola dio un giro en su carrera. </p>
            <p>Fue en una reunión de egresados del colegio donde todo cambió. Una amiga que trabajaba en Alba Holding la invitó a considerar postularse en la empresa. Para Paola, esto representaba una oportunidad de salir de su zona de confort. 
            <strong>"Siempre me han gustado los cambios y asumir riesgos"</strong>, dice. Así comenzó su aventura en un mundo completamente nuevo.  </p>
            <p><strong>Creciendo y Liderando Nuevos Proyectos</strong><br/>Paola empezó como monitora en Alba, pero rápidamente mostró su potencial para asumir más responsabilidades. Pronto se
             convirtió en entrenadora, y en poco tiempo estaba viajando para ayudar a abrir nuevas sedes y capacitar al personal. Hoy en día, como <strong>Analista de Entrenamiento</strong>, 
             su rol ha evolucionado hacia la formación de equipos y el desarrollo de nuevos talentos dentro de la empresa.  </p>
            </div>
            <div className='textinfo2'>
            <p><strong>"El mayor desafío fue pasar de ser entrenadora a líder de mis antiguos compañeros,"</strong> comenta Paola. Asumir un rol de liderazgo con personas que antes eran sus iguales no fue sencillo. Sin embargo, Paola encontró la clave para superar esta situación a través de la<strong>comunicación constante</strong>  
            y el acompañamiento cercano. Para ella, el éxito en el liderazgo no solo consiste en dirigir, sino en apoyar y guiar a su equipo en cada paso.  </p>
            <p><strong>Momentos de Transformación Personal y Profesional </strong><br/>Uno de los momentos más importantes de su carrera fue cuando, por una semana, asumió el cargo de gerencia.<strong> "Fue un desafío enorme, pero ver la confianza que el presidente de la empresa depositó en mí fue un impulso increíble,"</strong> 
            recuerda. Este fue uno de esos momentos que marcaron un antes y un después en su vida profesional.  </p>
            <p>Pero Paola no solo ha crecido dentro de la empresa; también ha alcanzado logros personales significativos. Recientemente, logró completar su carrera tecnológica y ahora está en proceso de remodelar 
            su apartamento, un sueño que ha trabajado duro para hacer realidad.  </p>
          <div className="Imginfo6">  <img  src={ImgPaoinf2} alt="Catalina"/></div>
            <div className='textinfo7'>
            <p><strong>Liderazgo con Pasión y Propósito </strong><br/>El liderazgo de Paola no solo se refleja en su capacidad para guiar a su equipo, sino en la manera en que ha transformado el trabajo diario. Pasó de un enfoque informal a uno más estructurado, donde la organización y la claridad de los procesos son clave 
            para el éxito. Su habilidad para escuchar y dar voz a cada miembro de su equipo ha creado un ambiente de trabajo colaborativo y productivo.</p>
            <p>Una de las anécdotas que más recuerda de su tiempo en Alba Holding es un viaje a la isla Múcura para una competencia interna. Fue una experiencia llena de risas, especialmente cuando, después de varias cervezas y muchas historias compartidas, la situación se tornó hilarante al buscar un lugar en medio del mar para "resolver" una necesidad apremiante. 
            <strong>"No puedo olvidar ese momento, todas las risas y cómo terminamos improvisando en el borde de la lancha,"</strong> cuenta Paola con una gran sonrisa.</p>
            <p><strong>Inspirada por el Ejemplo </strong><br/>Paola ha aprendido mucho en su tiempo en Alba Holding, pero si hay una persona que ha influido enormemente en su camino, es Camilo, el presidente de la empresa. Su dedicación, compromiso y capacidad de liderazgo han sido una inspiración constante para ella. 
            <strong>"Lo admiro profundamente por su compromiso con la compañía y su capacidad para liderar,"</strong> afirma Paola.  </p>
            <p>Su mayor motivación para seguir creciendo es simple: quiere ser un ejemplo para los demás, tanto en su vida personal como profesional. <strong>"Sueño con seguir creciendo y contribuir aún más al éxito de la empresa, pero también con brindar un futuro sólido a mi hogar y completar las remodelaciones de mi casa."</strong></p>
            <p><strong>Planes Futuros de Paola</strong><br/> 
            En el futuro, Paola se ve liderando más equipos y siendo una figura clave en la formación de nuevos talentos en la empresa. Además, uno de sus grandes objetivos es terminar su carrera en 
            Ingeniería Industrial y adquirir un nuevo inmueble. No tiene dudas de que, con su perseverancia y dedicación, alcanzará cada uno de esos sueños. </p>
            </div>
            <div className='textinfo4'>
            <p><strong>Lecciones de Vida y Consejos para Otros </strong><br/>Para aquellos que están pensando en cambiar de área o buscar una promoción, Paola tiene un mensaje claro:<strong> "Arriesgarse es la clave. Los cambios son positivos si tienes la actitud correcta y estás dispuesto a aprender."</strong> Además, para quienes asumen 
            su primer rol de liderazgo, les anima a no rendirse ante los desafíos:<strong> "Cada reto tiene una solución. La clave está en mantener la pasión y la perseverancia." </strong> </p>
            <p> <strong>Una Trayectoria que supera desafíos</strong><br/> Si Paola pudiera resumir su carrera en una sola palabra, sería <strong>"Evolución"</strong>. Desde su primer día como monitora hasta su puesto actual como <strong>Analista de 
            Entrenamiento</strong>, Paola ha crecido personal y profesionalmente. Ha superado desafíos, enfrentado inseguridades y se ha reinventado en cada etapa de su vida laboral.  </p>
            <p>En resumen, la historia de<strong> Angie Paola Sánchez</strong> es un ejemplo perfecto de cómo la pasión, la perseverancia y el deseo de aprender pueden llevar a una carrera llena de éxito. Su evolución en <strong>Alba Holding</strong> no solo es una fuente de 
            inspiración para quienes la rodean, sino también una muestra de lo que se puede lograr cuando se sigue adelante con determinación. </p>
            </div>

            </div>
          </div>
        );
      case 'mig':
        return (
          <div className="story-details">
           <hr className='hrcards'/>
          <div className='Tituinfo'><p > <strong> <span className='NomCards'>Miguel -</span> UN CAMINO DE TRANSFORMACIÓN <br/>Y EXITO EN ALBA HOLDING</strong> </p></div> 
           <div className="Imginfo4"> <img  src={ImgMiginf1} alt="Miguel" /></div>
            <div className='textinfo8'>
            <p >La historia de<strong> Miguel Ángel Londoño</strong>, Coordinador de OnlyFans en <strong>Alba Holding</strong>, 
            es una verdadera inspiración para todos aquellos que buscan superarse, reinventarse y seguir creciendo.</p>
            <p>Desde sus inicios como practicante hasta su actual rol de liderazgo, Miguel ha demostrado que el éxito es una mezcla de esfuerzo, pasión y adaptabilidad.</p>
            <p>Hoy, te invitamos a conocer su increíble trayectoria llena de desafíos y logros que lo han llevado a convertirse en una pieza clave dentro de la empresa. </p>
            <p><strong>De la Enfermería al Mundo del Talento Humano </strong><br/>Antes de unirse a <strong>Alba Holding</strong>, Miguel estaba explorando diferentes caminos profesionales. 
            Cursaba un técnico administrativo y, al mismo tiempo, trabajaba en el área de la salud como enfermero en un HomeCare.</p>
            <p>Su vida dio un giro inesperado cuando un profesor lo recomendó para realizar sus prácticas en el departamento de <strong>Talento Humano</strong> de Alba. 
            Lo que comenzó como una experiencia laboral, pronto se transformó en el inicio de una carrera que marcaría su vida. </p>
            <p><strong>Subiendo Peldaño a Peldaño </strong><br/>Miguel empezó como practicante en <strong>Talento Humano</strong>, gestionando solicitudes de selección a nivel nacional 
            para ciudades como Bogotá, Medellín y Cali. Su dedicación y capacidad para aprender rápidamente lo llevaron a ascender al puesto de<strong> Asistente de Talento Humano</strong>, 
            donde se encargaba de la contratación de personal administrativo, la gestión de la seguridad social y la creación de indicadores de rendimiento. Pero eso solo era el principio. </p>
            </div>
            <div className='textinfo2'>
            <p>Lo que más resalta en su carrera es su habilidad para adaptarse y asumir nuevos desafíos. Cada reto lo motivaba a seguir mejorando y aprendiendo, convirtiéndose en una persona 
                multifacética capaz de manejar diversas áreas con facilidad. Como él mismo dice:<strong> "Me apasionan los desafíos, especialmente cuando implican aprendizaje y crecimiento."</strong>  </p>
            <p><strong>Los Momentos Clave: Transformación Personal y Profesional </strong><br/>Uno de los momentos que más marcó a Miguel en su trayectoria fue cuando comenzó a asumir responsabilidades en 
            procesos completamente nuevos para él. Esta experiencia lo obligó a desarrollar habilidades que nunca antes había utilizado, lo que lo convirtió en alguien altamente adaptable. Esa flexibilidad, 
            sumada a su capacidad para identificar oportunidades de mejora, lo ha llevado a ser reconocido como un líder dentro de la empresa.  </p>
            <p><strong>"Pagar mi universidad, comprar mi propio vehículo y mejorar mi estilo de vida profesional y personal"</strong> son algunos de los logros que Miguel valora profundamente, frutos de su esfuerzo y dedicación en Alba Holding.</p>
            <p><strong>Un Líder que Aprende Rápido y Toma Decisiones con Confianza</strong><br/>Uno de los mayores desafíos que enfrentó al cambiar de rol fue la necesidad de tomar decisiones por sí mismo.<strong> "Estaba acostumbrado a seguir directrices, 
            pero ahora me encuentro tomando decisiones y analizando cada situación por mi cuenta"</strong>, comenta Miguel. Este cambio le permitió desarrollar un pensamiento crítico y estratégico, y hoy lidera con confianza,
             siempre buscando la mejor opción para cada situación. </p>
             <p>Su pasión por aprender es lo que lo sigue motivando a superarse. Miguel está comprometido en construir una carrera sólida dentro de la empresa y su próximo objetivo es incursionar en <strong>el área de tecnología</strong>. 
                Su sueño es combinar su amor por el desarrollo de software con su experiencia en gestión para crear soluciones innovadoras dentro de Alba Holding. </p>
           <div className="Imginfo5"> <img  src={ImgMiginf2} alt="Catalina"/></div>
            <div className='textinfo9'>
            <p><strong>Un Reconocimiento Inolvidable</strong><br/>Entre las anécdotas que más atesora Miguel, destaca el día en que fue postulado como <strong>mejor empleado del mes</strong>. Aunque no estaba acostumbrado a recibir este tipo de reconocimientos, 
            ese momento fue un verdadero impulso para seguir esforzándose.<strong> "Ese reconocimiento validó todo mi esfuerzo y me motivó aún más a seguir creciendo"</strong>, dice con orgullo.  </p>
            <p><strong>El Futuro de Miguel: Metas y Sueños por Cumplir </strong><br/>Cuando Miguel mira hacia el futuro, se ve como un líder consolidado, con un equipo a su cargo y contribuyendo significativamente al éxito de la empresa. Sus sueños no solo 
            están relacionados con lo profesional; también desea comprar su propio apartamento y regalarle una casa a sus padres como agradecimiento por todo su apoyo. Además, planea estudiar ingeniería con especialización <strong>en TIC’s</strong>, una meta que refleja su deseo de seguir aprendiendo y creciendo.  </p>
            <p> <strong>Más Allá del Trabajo: Hobbies que Enriquecen su Vida </strong><br/>Fuera del trabajo, Miguel disfruta de actividades que lo ayudan a mantener un equilibrio entre su vida personal y profesional. Escuchar música, jugar videojuegos, viajar y meditar son algunas de sus pasiones. Estas actividades no 
            solo le permiten relajarse, sino que también fomentan su creatividad y le brindan nuevas perspectivas. "La meditación me ayuda a centrarme y mejorar mi enfoque, lo que a su vez impacta positivamente en mi rendimiento profesional", comenta. </p>
           <p><strong>Una Trayectoria de Transformación Continua </strong><br/>Si Miguel pudiera describir su camino en una sola palabra, esa sería <strong>"transformación"</strong>. Desde sus primeros pasos en Alba Holding hasta su desarrollo como líder, cada experiencia lo ha llevado a evolucionar y adaptarse. La transformación 
           no es solo una palabra para él, es el reflejo de su compromiso con el aprendizaje continuo y su disposición para asumir nuevos desafíos. </p>
            </div>
            <div className='textinfo4'>
            <p>En resumen, la historia de Miguel Ángel Londoño es un ejemplo de cómo la perseverancia, la pasión por aprender y la capacidad de adaptarse pueden transformar una carrera. Su éxito en Alba Holding no solo es el resultado de su esfuerzo, 
                sino también de su visión a largo plazo y su disposición para seguir creciendo. Y sin duda, su historia seguirá siendo un caso de éxito que inspire a muchos más.  </p>
            </div>

            </div>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`Succes2 ${selectedStory ? 'story-background' : 'default-background'}`}>
      {selectedStory ? (
        <div>
            <div className='btonback'>
            <button onClick={() => setSelectedStory(null)} className="page-button arrow-button">
              <FaChevronLeft /> 
              
            </button>
            <span className="pagination-text">
                  <strong>Regresar</strong> 
                </span>
            </div>
        {renderStoryDetails()}
          <div className="navigation-buttons">
            <button onClick={goToBackStory } className="page-button arrow-button">
              <FaChevronLeft /> 
            </button>
            <span className="pagination-text">
                  <strong>Anterior</strong> 
                </span>
                <span className="pagination-text">
                  <strong>Siguiente</strong> 
                </span>
            <button onClick={goToNextStory} className="page-button arrow-button">
            <FaChevronRight />
            </button>
          </div>
        
        </div>
      ) : (
        <>
          {isMobile || isTablet ? (
            <div className="row1">
              <div className="IntroduccionBlog">
                <p>
                  ¡Definimos <span>nuestro </span>
                  <span>propósito</span> al crear <br /> empresas diseñadas
                  para alcanzar <span>el éxito!</span>
                </p>
              </div>
            </div>
          ) : (
            <div className="left-column">
              <hr className='hrprincipal' />
              <p >
                <strong>Descubre historias que inspiran y motivan.</strong> Conoce
                cómo nuestros colaboradores han transformado su pasión <br />
                en logros dentro de nuestra empresa,superando retos, alcanzando metas y creciendo junto a nosotros.
              </p>

              <div className="cards-container">
                {currentBlogs.map((blog) => (
                  <div key={blog.id} className={blog.className}>
                    <img src={blog.img} className="imgcata" alt={blog.title} />
                    <div className="Cat-Container">
                      <p>{blog.title}</p>
                      <hr className="hrcard" />
                      <h1>{blog.description}</h1>
                      <button
                        className="Cbutton"
                        onClick={() => setSelectedStory(blog.id)}
                      >
                        LEER MÁS <span className="arrow">&gt;</span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <div className="Paginacion">
                <button
                  onClick={prevPage}
                  className="page-button arrow-button"
                  disabled={currentPage === 1}
                >
                   <FaChevronLeft /> 
                </button>
                <span className="pagination-text">
                  <strong>Página {currentPage}</strong> de {totalPages}
                </span>
                <button
                  onClick={nextPage}
                  className="page-button arrow-button"
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const SuccessStories = () => {
  return (
    <>
      <Banner1 />
      <Banner2 />
    </>
  );
};

export default SuccessStories;
