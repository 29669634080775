import './styles/App.scss';
import { Routes, Route } from 'react-router-dom'
import Home from './pages/HomePage'
import Login from './pages/LoginPage'
import Register from './pages/RegisterPage'
import Restore from './pages/RestorePage'
import Webinar from './pages/WebinarPage'
import SobreNosotrosPage from './pages/SobreNosotrosPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { Layout } from './components/Layout';
import VacanciesPage from './pages/VacanciesPage';
import Blogs from './pages/BlogPage';
import PrivateRoute from './components/PrivateRoute';
import SuccessStories from './pages/Successstories';

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='/webinar' element={<Webinar />} />
        <Route path='/sobrenosotros' element={<SobreNosotrosPage/>}/>
        <Route path='/vacantes' element={<VacanciesPage />} />
        <Route path='/CasosdeExito' element={<SuccessStories/>}/>
        {/* Ruta dinámica para el blog */}
        <Route path='/blog/:category' element={<Blogs />} /> 
      </Route>
      <Route path='/login' element={<Login />} />
      <Route path='/Restore' element={<Restore />} />
      <Route path='/registro' element={<Register />} />
      <Route path='*' element={<NotFoundPage />} />

    </Routes>
  );
}


export default App;
